import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  name: 'eTax Learning',
  created() {
    document.title = `eTax Learning`;
  },
  watch: {
    $route(to) {
       document.title = `eTax Learning - ${to.meta.title}`;
      
    }
  },
  render: (h) => h(App),
}).$mount("#app")


