import Api from "../index";

export default {
  getList(credentials) {
    return Api().post("web/chat/getList", {
      amount: credentials.amount,
      page: credentials.page,
      keySearch: credentials.keySearch,
    });
  },
 
  getTotal(credentials) {
    return Api().post("web/chat/getTotal", {
      keySearch: credentials.keySearch,
    });
  },
 
  delete(credentials) {
    return Api().post("web/chat/delete", {
      id: credentials,
    });
  },

};
