<template>
  <div class="content">
    <div>Cảm ơn bạn đến với ứng dụng Kiến Thức Thuế</div>
    <div>Mọi thông tin của bạn sẽ được bảo vệ tốt nhất. Khi bạn là thành viên của ứng dụng thì chỉ có duy nhất chúng tôi là người có thể sử dụng các thông tin của bạn.
</div>
  </div>
</template>

<script>

export default {
  data() {
    return {
    }
  },

};
</script>
