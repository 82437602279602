const state = {
  drawer: true,
 
}
const getters = {
    drawer: (state) => {
    return state.drawer
  },
  getItems: (state) => {
    return state.items
  },
}

const mutations = {
  changeStatusDrawerOption(state) {
    state.drawer = !state.drawer
  },
}

const actions = {
  changeStatusDrawerAction: ({ commit }) => {
    commit("changeStatusDrawerOption")
  },
}

export default { state, getters, mutations, actions }
