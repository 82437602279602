<template>
  <div class="content">
    <b-form @submit.prevent="onSearch">
      <b-input-group class="mb-2">
        <b-form-input
          type="search"
          v-model="keySearch"
          placeholder="Tìm kiếm"
        ></b-form-input>
        <b-button @click="onSearch" class="rounded-0" variant="outline-primary">
          <b-icon icon="search"></b-icon>
        </b-button>
      </b-input-group>
    </b-form>
    <v-container>
      <b-row class="my-1 text-end">
        <b-col cols="12">
          <b-button variant="primary" @click="showModalAdd">Thêm mới</b-button>
        </b-col>
      </b-row>
    </v-container>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Bài học</h4>
          </div>
          <div class="card-body">
            <table class="table table-striped walla">
              <thead>
                <tr align="center">
                  <th>STT</th>
                  <th>Tên bài</th>
                  <th>Miêu tả</th>
                  <th>Danh mục trang chủ</th>
                  <th>Ảnh</th>
                  <th>Trạng thái</th>
                  <th>Tác vụ</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(post, index) in postList"
                  :key="post.id"
                  align="center"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ post.name }}</td>
                  <td>{{ post.description }}</td>
                  <td>{{ post.homeCategory[0].title }}</td>
                  <td>
                    <b-avatar
                      button
                      @click="clickImage(`${base_url}` + post.image)"
                      :src="`${base_url}` + post.image"
                    >
                    </b-avatar>
                  </td>
                  <td>{{ post.isLock ? "Khóa" : "Mở khóa" }}</td>
                  <td>
                    <v-btn
                      x-small
                      tile
                      @click="showModalOpen(post)"
                      color="success"
                      class="btn-sm py-3 mr-4"
                    >
                      Mở khóa
                    </v-btn>
                    <v-btn
                      x-small
                      tile
                      @click="showModalLock(post)"
                      color="amber"
                      class="btn-sm py-3 mr-4"
                    >
                      Khóa
                    </v-btn>
                    <v-btn
                      x-small
                      tile
                      color="primary"
                      class="btn-sm py-3 mr-4"
                      @click="viewDetail(post)"
                    >
                      Xem
                    </v-btn>
                    <v-btn
                      x-small
                      tile
                      @click="showModalEdit(post)"
                      color="success"
                      class="btn-sm py-3 mr-4"
                    >
                      Sửa
                    </v-btn>
                    <v-btn
                      @click="showModalDelete(post)"
                      x-small
                      tile
                      color="error"
                      class="btn-sm py-3"
                    >
                      Xóa
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Số trang -->
    <b-pagination
      v-model="currentPage"
      :total-rows="total"
      :per-page="amount"
      aria-controls="my-table"
      class="my-6"
      align="end"
    ></b-pagination>
    <!-- Model View -->
    <b-modal
      size="lg"
      ref="modal-view"
      hide-footer
      :body-text-variant="primary"
      title="Xem thông tin"
    >
      <b-container fluid v-if="dataView != null">
        <b-row class="mb-1 text-center">
          <b-col cols="12">
            <b-avatar
              button
              @click="clickImage(`${base_url}` + dataView.image)"
              :src="`${base_url}` + dataView.image"
              size="12rem"
            ></b-avatar>
          </b-col>
        </b-row>
        <!-- Tên bài -->
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Tên bài:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              disabled
              v-model="dataView.name"
            ></b-form-input>
          </b-col>
        </b-row>
        <!-- Miêu tả -->
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Miêu tả:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              disabled
              v-model="dataView.description"
            ></b-form-input>
          </b-col>
        </b-row>
        <!-- Link tài liệu -->
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Link bài liệu:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="dataView.linkDocument"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>
        <!-- Link Video -->
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Link video:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="dataView.linkVideo"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>
        <!-- Thoi gian Video -->
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Thời gian video(Giây):</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="dataView.time"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>
        <!-- Danh muc trang chu -->
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Danh mục trang chủ:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="dataView.homeCategory[0].title"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <!-- Model Add -->
    <b-modal
      size="lg"
      ref="modal-add"
      hide-footer
      :body-text-variant="primary"
      title="Thêm mới"
    >
      <b-container fluid>
        <b-row class="mb-1 text-center">
          <b-col cols="12">
            <b-avatar
              button
              @click="$refs.file.click()"
              :src="fileSrc"
              size="12rem"
            ></b-avatar>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12">
            <input
              type="file"
              id="file"
              ref="file"
              style="display: none"
              v-on:change="selectFileDone()"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <label for="input-small">Tên bài:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="name"
              placeholder="Nhập tên bài"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <label for="input-small">Link tài liệu:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="linkDocument"
              placeholder="Nhập link tài liệu"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <label for="input-small">Miêu tả:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="description"
              placeholder="Nhập miêu tả"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <label for="input-small">Link video:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="linkVideo"
              placeholder="Nhập link video"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <label for="input-small">Thời gian video(Giây):</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="time"
              placeholder="Nhập thời gian video"
            ></b-form-input>
          </b-col>
        </b-row>
        <!-- Tìm kiếm danh mục trang chủ -->
        <b-row>
          <b-col cols="2">
            <label for="input-small">Danh mục trang chủ:</label>
          </b-col>
          <b-col cols="10">
            <b-input-group>
              <b-form-input
                id="input-small"
                size="sm"
                v-model="keySearchCategoryHome"
                placeholder="Tìm kiếm danh mục trang chủ"
              ></b-form-input>
              <b-button
                class="rounded-0"
                @click="onSearchCategoryHome"
                size="sm"
                variant="outline-primary"
              >
                <b-icon icon="search"></b-icon>
              </b-button>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <label for="input-small"></label>
          </b-col>
          <b-col cols="10">
            <v-container class="px-0 py-0 my-0" fluid>
              <b-form-group
                v-for="categoryHome in listCategoryHomeSearch"
                :key="categoryHome._id"
              >
                <b-form-radio
                  v-model="categoryHomeSelected"
                  name="categoryHome"
                  :value="`${categoryHome._id}`"
                >
                  <b-avatar
                    :src="`${base_url}` + categoryHome.image"
                    size="2rem"
                  ></b-avatar>
                  {{ categoryHome.title }}</b-form-radio
                >
              </b-form-group>
            </v-container>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12" class="text-center">
            <b-overlay
              :show="loadingAdd"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button variant="primary" @click="handleAdd">Thêm mới</b-button>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Model Edit -->
    <b-modal
      size="lg"
      ref="modal-edit"
      hide-footer
      :body-text-variant="primary"
      title="Sửa thông tin"
    >
      <b-container fluid>
        <b-row class="mb-1 text-center">
          <b-col cols="12">
            <b-avatar
              button
              @click="$refs.file.click()"
              :src="fileSrc"
              size="12rem"
            ></b-avatar>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12">
            <input
              type="file"
              id="file"
              ref="file"
              style="display: none"
              v-on:change="selectFileDone()"
            />
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Tên bài:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="name"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Link tài liệu:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="linkDocument"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Miêu tả:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="description"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Link video:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="linkVideo"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Thời gian video(Giây):</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="time"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-1">
          <b-col cols="12" class="text-center">
            <b-overlay
              :show="loadingAdd"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button variant="primary" @click="handleEdit"
                >Cập nhật</b-button
              >
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <!-- Modal confirm delete -->
    <b-modal
      ref="modal-delete"
      @ok="onDelete()"
      cancel-title="Hủy"
      ok-title="Đồng ý"
      ok-
      title="Xóa"
    >
      <p class="my-4">Bạn có đồng ý xóa?</p>
    </b-modal>
     <!-- Modal confirm open -->
    <b-modal
      ref="modal-open"
      @ok="onOpen()"
      cancel-title="Hủy"
      ok-title="Đồng ý"
      ok-
      title="Xóa"
    >
      <p class="my-4">Bạn có đồng ý mở khóa bài học?</p>
    </b-modal>

    <!-- Modal confirm lock -->
    <b-modal
      ref="modal-lock"
      @ok="onLock()"
      cancel-title="Hủy"
      ok-title="Đồng ý"
      ok-
      title="Xóa"
    >
      <p class="my-4">Bạn có đồng ý khóa bài học?</p>
    </b-modal>
  </div>
</template>

<script>
import PostApi from "../apis/modules/post";
import CategoryHome from "../apis/modules/home_category";

export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API,
      postList: [],
      amount: 10,
      page: 1,
      total: 0,
      keySearch: "",
      currentPage: 1,
      file: null,
      name: null,
      time: null,
      keySearchCategoryHome: "",
      listCategoryHomeSearch: [],
      linkVideo: null,
      linkDocument: null,
      description: null,
      loadingAdd: false,
      fileSrc: null,
      categoryHomeSelected: null,
      dataView: null,
      idEdit: null,
      idDelete: null,
      type: "Challenge",
      idStatus: null
    };
  },
  watch: {
    currentPage: {
      handler: function (newPage) {
        this.page = newPage;
        this.getListFromSever();
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      PostApi.getTotal({ keySearch: this.keySearch })
        .then((response) => {
          this.total = response.data.data;
          this.getListFromSever();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickImage(image) {
      window.open(image, "_blank");
    },
    getListFromSever() {
      PostApi.getList({
        amount: this.amount,
        page: this.page - 1,
        keySearch: this.keySearch,
      })
        .then((response) => {
          this.postList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSearch() {
      this.page = 1;
      this.init();
    },
    showModalAdd() {
      this.clearDataAdd();
      this.$refs["modal-add"].show();
    },
    clearDataAdd() {
      this.file = null;
      this.name = null;
      this.time = null;
      this.linkVideo = null;
      this.linkDocument = null;
      this.description = null;
      this.fileSrc = null;
      this.categoryHomeSelected =null;
      (this.keySearchCategoryHome = ""), (this.idEdit = null);
      this.idDelete = null;
      this.listCategoryHomeSearch = [];
      this.type = "Challenge";
    },
    selectFileDone() {
      this.file = this.$refs.file.files[0];

      this.fileSrc = URL.createObjectURL(this.file);
    },
    handleAdd() {
      this.loadingAdd = true;
      if (this.file == null) {
        this.$toast.error("Vui lòng chọn ảnh");
        this.loadingAdd = false;
        return;
      }
      if (this.name == null) {
        this.$toast.error("Vui lòng nhập tên bài");
        this.loadingAdd = false;
        return;
      }

      if (this.linkDocument == null) {
        this.$toast.error("Vui lòng link tài liệu");
        this.loadingAdd = false;
        return;
      }

      if (this.description == null) {
        this.$toast.error("Vui lòng nhập miêu tả");
        this.loadingAdd = false;
        return;
      }

      if (this.linkVideo == null) {
        this.$toast.error("Vui lòng nhập link video");
        this.loadingAdd = false;
        return;
      }
      if (this.time == null) {
        this.$toast.error("Vui lòng nhập thời gian video");
        this.loadingAdd = false;
        return;
      }
      if (this.categoryHomeSelected == null) {
        this.$toast.error("Vui lòng chọn danh mục trang chủ");
        this.loadingAdd = false;
        return;
      }
      let formData = new FormData();
      formData.append("image", this.file);
      formData.append("name", this.name);
      formData.append("time", this.time);
      formData.append("description", this.description);
      formData.append("linkVideo", this.linkVideo);
      formData.append("idHomeCategory", this.categoryHomeSelected);
      formData.append("linkDocument", this.linkDocument);
      formData.append("type", this.type);
      PostApi.add(formData)
        .then((response) => {
          this.postList.push(response.data.data);
          this.$toast.success("Thêm thành công");
        })
        .catch(() => {
          this.$toast.error("Thêm thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-add"].hide();
          this.clearDataAdd();
        });
    },
    viewDetail(item) {
      this.dataView = item;
      this.$refs["modal-view"].show();
    },
    showModalEdit(item) {
      this.clearDataAdd();
      this.fileSrc = this.base_url + item.image;
      this.name = item.name;
      this.time = item.time;
      this.linkVideo = item.linkVideo;
      this.description = item.description;
      this.linkDocument = item.linkDocument;
      this.idEdit = item._id;
      this.description = item.description;
      this.$refs["modal-edit"].show();
    },
    handleEdit() {
      this.loadingAdd = true;
      if (this.name == null) {
        this.$toast.error("Vui lòng nhập tên bài");
        this.loadingAdd = false;
        return;
      }
      if (this.linkDocument == null) {
        this.$toast.error("Vui lòng nhập link tài liệu");
        this.loadingAdd = false;
        return;
      }
      if (this.description == null) {
        this.$toast.error("Vui lòng nhập miêu tả");
        this.loadingAdd = false;
        return;
      }
      if (this.linkVideo == null) {
        this.$toast.error("Vui lòng nhập link video");
        this.loadingAdd = false;
        return;
      }
      if (this.time == null) {
        this.$toast.error("Vui lòng nhập thời gian video");
        this.loadingAdd = false;
        return;
      }

      let formData = new FormData();
      if (this.file != null) {
        formData.append(
          "linkImageRemove",
          this.fileSrc.replace(this.base_url, "")
        );
        formData.append("image", this.file);
      }
      formData.append("name", this.name);
      formData.append("time", this.time);
      formData.append("description", this.description);
      formData.append("linkVideo", this.linkVideo);
      formData.append("linkDocument", this.linkDocument);
      formData.append("id", this.idEdit);
      PostApi.edit(formData)
        .then((response) => {
          const objIndex = this.postList.findIndex(
            (obj) => obj._id == response.data.data._id
          );
          this.postList[objIndex] = response.data.data;
          this.$toast.success("Cập nhật thành công");
        })
        .catch(() => {
          this.$toast.error("Cập nhật thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-edit"].hide();
          this.clearDataAdd();
        });
    },
    showModalDelete(artist) {
      this.idDelete = artist._id;
      this.$refs["modal-delete"].show();
    },
    hideModalDelete() {
      this.$refs["modal-delete"].hide();
    },
    onDelete() {
      PostApi.delete(this.idDelete)
        .then(() => {
          console.log("ABC");
          const idDeleted = this.idDelete;
          this.postList = this.postList.filter(function (item) {
            return item._id !== idDeleted;
          });
          this.$toast.success("Xóa thành công");
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("Xóa thất bại");
        })
        .finally(() => {
          this.$refs["modal-delete"].hide();
          this.clearDataAdd();
        });
    },
    onSearchCategoryHome() {
      CategoryHome.getList({ keySearch: this.keySearchCategoryHome,amount: 3,
      page: 0, })
        .then((response) => {
          console.log(response.data.data);
          this.listCategoryHomeSearch = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showModalOpen(post) {
      this.idStatus = post._id;
      this.$refs["modal-open"].show();
    },
    onOpen() {
      this.loadingAdd = true;

      PostApi.changeLock({
        idPost: this.idStatus,
        status: false,
      })
        .then((response) => {
          const objIndex = this.postList.findIndex(
            (obj) => obj._id == response.data.data._id
          );
          this.postList[objIndex] = response.data.data;
          this.$toast.success("Mở khóa thành công");
        })
        .catch(() => {
          this.$toast.error("Mở khóa thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-open"].hide();
          this.idStatus = null;
        });
    },
     showModalLock(post) {
      this.idStatus = post._id;
      this.$refs["modal-lock"].show();
    },
     onLock() {
      this.loadingAdd = true;

      PostApi.changeLock({
        idPost: this.idStatus,
        status: true,
      })
        .then((response) => {
          const objIndex = this.postList.findIndex(
            (obj) => obj._id == response.data.data._id
          );
          this.postList[objIndex] = response.data.data;
          this.$toast.success("Khóa thành công");
        })
        .catch(() => {
          this.$toast.error("Khóa thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-lock"].hide();
          this.idStatus = null;
        });
    },
  },
};
</script>
