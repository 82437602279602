import Api from "../index";

export default {
  getList(credentials) {
    return Api().post("web/notification/getList", {
      amount: credentials.amount,
      page: credentials.page,
      keySearch: credentials.keySearch,
    });
  },
  getTotal(credentials) {
    return Api().post("web/notification/getTotal", {
      keySearch: credentials.keySearch,
    });
  },
  add(credentials) {
    return Api().post("web/notification/add", credentials);
  },
  delete(credentials) {
    return Api().post("web/notification/delete", {
        idNotification: credentials,
    });
  },
};
