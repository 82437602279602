<template>
  <div class="content">
    <b-form @submit.prevent="onSearch">
      <b-input-group class="mb-2">
        <b-form-input
          type="search"
          v-model="keySearch"
          placeholder="Tìm kiếm"
        ></b-form-input>
        <b-button @click="onSearch" class="rounded-0" variant="outline-primary">
          <b-icon icon="search"></b-icon>
        </b-button>
      </b-input-group>
    </b-form>
    <v-container>
      <b-row class="my-1 text-end">
        <b-col cols="12">
          <b-button variant="primary" @click="showModalAdd">Thêm mới</b-button>
        </b-col>
      </b-row>
    </v-container>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Danh sách File PDF</h4>
          </div>
          <div class="card-body">
            <table class="table table-striped walla">
              <thead>
                <tr align="center">
                  <th>STT</th>
                  <th>Miêu tả</th>
                  <th>Đường dẫn</th>
                  <th>Ngày đăng</th>
                  <th>Tác vụ</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(pdf, index) in pdfList"
                  :key="pdf.id"
                  align="center"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ pdf.description }}</td>
                  <td>{{ pdf.link }}</td>
                  <td>{{ new Date(pdf.createdDate).toLocaleString() }}</td>
                  <td>
                   
                    <v-btn
                      x-small
                      tile
                      @click="showModalEdit(pdf)"
                      color="success"
                      class="btn-sm py-3 mr-4"
                    >
                      Sửa
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Số trang -->
    <b-pagination
      v-model="currentPage"
      :total-rows="total"
      :per-page="amount"
      aria-controls="my-table"
      class="my-6"
      align="end"
    ></b-pagination>
   

    <!-- Model Add -->
    <b-modal
      size="lg"
      ref="modal-add"
      hide-footer
      :body-text-variant="primary"
      title="Thêm mới"
    >
      <b-container fluid>
        <b-row class="my-1">
          <b-col cols="12">
            <input
              type="file"
              id="file"
              ref="file"
              v-on:change="selectFileDone()"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <label for="input-small">Miêu tả:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="description"
              placeholder="Nhập miêu tả"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12" class="text-center">
            <b-overlay
              :show="loadingAdd"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button variant="primary" @click="handleAdd">Thêm mới</b-button>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Model Edit -->
    <b-modal
      size="lg"
      ref="modal-edit"
      hide-footer
      :body-text-variant="primary"
      title="Sửa thông tin"
    >
      <b-container fluid>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Miêu tả:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="description"
            ></b-form-input>
          </b-col>
        </b-row>

    

        <b-row class="my-1">
          <b-col cols="12" class="text-center">
            <b-overlay
              :show="loadingAdd"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button variant="primary" @click="handleEdit"
                >Cập nhật</b-button
              >
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import PdfApi from "../apis/modules/pdf";

export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API,
      pdfList: [],
      amount: 10,
      page: 1,
      total: 0,
      keySearch: "",
      currentPage: 1,
      file: null,
      description: null,
      loadingAdd: false,
      fileSrc: null,
      idEdit: null,
    };
  },
  watch: {
    currentPage: {
      handler: function (newPage) {
        this.page = newPage;
        this.getListFromSever();
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      PdfApi.getTotal({ keySearch: this.keySearch })
        .then((response) => {
          this.total = response.data.data;
          this.getListFromSever();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getListFromSever() {
      PdfApi.getList({
        amount: this.amount,
        page: this.page - 1,
        keySearch: this.keySearch,
      })
        .then((response) => {
          this.pdfList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSearch() {
      this.page = 1;
      this.init();
    },
    showModalAdd() {
      this.clearDataAdd();
      this.$refs["modal-add"].show();
    },
    clearDataAdd() {
      this.file = null;
      this.description = null;
      this.fileSrc = null;
      this.idEdit = null;
    },
    selectFileDone() {
      this.file = this.$refs.file.files[0];

      this.fileSrc = URL.createObjectURL(this.file);
    },
    handleAdd() {
      this.loadingAdd = true;
      if (this.file == null) {
        this.$toast.error("Vui lòng chọn ảnh");
        this.loadingAdd = false;
        return;
      }
      
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("description", this.description);
      PdfApi.add(formData)
        .then((response) => {
          this.pdfList.push(response.data.data);
          this.$toast.success("Thêm thành công");
        })
        .catch(() => {
          this.$toast.error("Thêm thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-add"].hide();
          this.clearDataAdd();
        });
    },
    
    showModalEdit(item) {
      this.clearDataAdd();
      this.fileSrc = this.base_url + item.image;
      this.title = item.title;
      this.idEdit = item._id;
      this.description = item.description;
      this.$refs["modal-edit"].show();
    },
    handleEdit() {
      this.loadingAdd = true;      
      PdfApi.edit({
          'id':this.idEdit,
          'description':this.description})
        .then((response) => {
          const objIndex = this.pdfList.findIndex(
            (obj) => obj._id == response.data.data._id
          );
          this.pdfList[objIndex] = response.data.data;
          this.$toast.success("Cập nhật thành công");
        })
        .catch(() => {
          this.$toast.error("Cập nhật thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-edit"].hide();
          this.clearDataAdd();
        });
    },
   
    
  },
};
</script>
