<template>
  <div class="content">
    <b-form @submit.prevent="onSearch">
      <b-input-group class="mb-2">
        <b-form-input
          type="search"
          v-model="keySearch"
          placeholder="Tìm kiếm"
        ></b-form-input>
        <b-button @click="onSearch" class="rounded-0" variant="outline-primary">
          <b-icon icon="search"></b-icon>
        </b-button>
      </b-input-group>
    </b-form>
    <v-container>
      <b-row class="my-1 text-end">
        <b-col cols="12">
          <b-button variant="primary" @click="showModalAdd">Thêm mới</b-button>
        </b-col>
      </b-row>
    </v-container>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Danh sách người dùng</h4>
          </div>
          <div class="card-body">
            <table class="table table-striped walla">
              <thead>
                <tr align="center">
                  <th>STT</th>
                  <th>Tên</th>
                  <th>Ảnh</th>
                  <th>Trạng thái</th>
                  <th>Email</th>
                  <th>Tác vụ</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(account, index) in accountList"
                  :key="account.id"
                  align="center"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ account.name }}</td>
                  <td>
                    <b-avatar
                      button
                      @click="clickImage(`${base_url}` + account.avatar)"
                      :src="`${base_url}` + account.avatar"
                    >
                    </b-avatar>
                  </td>
                  <td>{{ account.isLock?"Đang bị khóa":"Đang hoạt động" }}</td>
                  <td>{{ account.email }}</td>
                  <td>
                    <v-btn
                      x-small
                      tile
                      @click="showModalOpen(account)"
                      color="success"
                      class="btn-sm py-3 mr-4"
                    >
                      Mở khóa
                    </v-btn>
                    <v-btn
                      x-small
                      tile
                      @click="showModalLock(account)"
                      color="primary"
                      class="btn-sm py-3 mr-4"
                    >
                      Khóa
                    </v-btn>
                    <v-btn
                      @click="showModalDelete(account)"
                      x-small
                      tile
                      color="error"
                      class="btn-sm py-3"
                    >
                      Xóa
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Số trang -->
    <b-pagination
      v-model="currentPage"
      :total-rows="total"
      :per-page="amount"
      aria-controls="my-table"
      class="my-6"
      align="end"
    ></b-pagination>
    <!-- Modal confirm open -->
    <b-modal
      ref="modal-open"
      @ok="onOpen()"
      cancel-title="Hủy"
      ok-title="Đồng ý"
      ok-
      title="Xóa"
    >
      <p class="my-4">Bạn có đồng ý mở khóa tài khoản?</p>
    </b-modal>

    <!-- Modal confirm lock -->
    <b-modal
      ref="modal-lock"
      @ok="onLock()"
      cancel-title="Hủy"
      ok-title="Đồng ý"
      ok-
      title="Xóa"
    >
      <p class="my-4">Bạn có đồng ý khóa tài khoản?</p>
    </b-modal>
    <!-- Modal confirm delete -->
    <b-modal
      ref="modal-delete"
      @ok="onDelete()"
      cancel-title="Hủy"
      ok-title="Đồng ý"
      ok-
      title="Xóa"
    >
      <p class="my-4">Bạn có đồng ý xóa?</p>
    </b-modal>
    <!-- Model add account -->
    <b-modal
      size="lg"
      ref="modal-add"
      scrollable
      hide-footer
      :body-text-variant="primary"
      title="Thêm mới"
    >
      <b-container fluid>
      
        <b-row>
          <b-col cols="2">
            <label for="input-small">Email:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="email"
              placeholder="Nhập email"
            ></b-form-input>
          </b-col>
        </b-row>
         
        <b-row>
          <b-col cols="2">
            <label for="input-small">Họ và tên:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="name"
              placeholder="Nhập họ và tên"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <label for="input-small">Mật khẩu:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="password"
              placeholder="Nhập mật khẩu"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12" class="text-center">
            <b-overlay
              :show="loadingAdd"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button variant="primary" @click="handleAdd">Thêm mới</b-button>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import AccountApi from "../apis/modules/account";

export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API,
      accountList: [],
      amount: 10,
      page: 1,
      total: 0,
      keySearch: "",
      currentPage: 1,
      idStatus: null,
      idDelete: null,
      email:null,
      password:null,
      name:null,
      loadingAdd: false
    };
  },
  watch: {
    currentPage: {
      handler: function (newPage) {
        this.page = newPage;
        this.getListFromSever();
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      AccountApi.getTotal({ keySearch: this.keySearch })
        .then((response) => {
          this.total = response.data.data;
          this.getListFromSever();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getListFromSever() {
      AccountApi.getList({
        amount: this.amount,
        page: this.page - 1,
        keySearch: this.keySearch,
      })
        .then((response) => {
          this.accountList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickImage(image) {
      window.open(image, "_blank");
    },
    onSearch() {
      this.page = 1;
      this.init();
    },
    showModalOpen(account) {
      this.idStatus = account._id;
      this.$refs["modal-open"].show();
    },
    showModalLock(account) {
      this.idStatus = account._id;
      this.$refs["modal-lock"].show();
    },
    onLock() {
      this.loadingAdd = true;

      AccountApi.changeLock({
        idUser: this.idStatus,
        status: true,
      })
        .then((response) => {
          const objIndex = this.accountList.findIndex(
            (obj) => obj._id == response.data.data._id
          );
          const idDeleted = this.idStatus;
          this.accountList = this.accountList.filter(function (item) {
            return item._id !== idDeleted;
          });
          this.accountList.splice(objIndex, 0, response.data.data)
          this.$toast.success("Khóa thành công");
        })
        .catch(() => {
          this.$toast.error("Khóa thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-lock"].hide();
          this.idStatus = null;
        });
    },
    onOpen() {
      this.loadingAdd = true;

      AccountApi.changeLock({
        idUser: this.idStatus,
        status: false,
      })
        .then((response) => {
          const objIndex = this.accountList.findIndex(
            (obj) => obj._id == response.data.data._id
          );
          const idDeleted = this.idStatus;
          this.accountList = this.accountList.filter(function (item) {
            return item._id !== idDeleted;
          });
          this.accountList.splice(objIndex, 0, response.data.data)
          this.$toast.success("Mở khóa thành công");
        })
        .catch(() => {
          this.$toast.error("Mở khóa thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-open"].hide();
          this.idStatus = null;
        });
    },
    showModalDelete(account) {
      this.idDelete = account._id;
      this.$refs["modal-delete"].show();
    },
    onDelete() {
      AccountApi.delete(this.idDelete)
        .then(() => {
          console.log("ABC");
          const idDeleted = this.idDelete;
          this.accountList = this.accountList.filter(function (item) {
            return item._id !== idDeleted;
          });
          this.$toast.success("Xóa thành công");
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("Xóa thất bại");
        })
        .finally(() => {
          this.$refs["modal-delete"].hide();
        });
    },
    showModalAdd() {
      this.clearDataAdd();
      this.$refs["modal-add"].show();
    },
    clearDataAdd() {
      this.name = null;
      this.password = null;
      this.email = null;
    },
    handleAdd() {
      this.loadingAdd = true;
      
      if (this.email == null) {
        this.$toast.error("Vui lòng nhập email");
        this.loadingAdd = false;
        return;
      }
      if (this.name == null) {
        this.$toast.error("Vui lòng nhập họ và tên");
        this.loadingAdd = false;
        return;
      }
      if (this.password == null) {
        this.$toast.error("Vui lòng nhập mật khẩu");
        this.loadingAdd = false;
        return;
      }
      AccountApi.add({
          name:this.name,
          password:this.password,
          email: this.email
      })
        .then((response) => {
          if(response.data.responseCode=="RA_01"){
            this.onSearch();
            this.$toast.success("Thêm thành công");
          }else{
             this.$toast.error(response.data.message);
          }
          
        })
        .catch(() => {
          this.$toast.error("Thêm thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-add"].hide();
          this.clearDataAdd();
        });
    },
  },
};
</script>
