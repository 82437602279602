<template>
  <v-app id="inspire">
    <Navbar v-if="loggedIn" />
    <v-app-bar app>
      <v-app-bar-nav-icon
        @click="changeStatusDrawerAction"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn v-if="loggedIn" tile class="error" @click.stop="showModal">
        <v-icon left>mdi-logout</v-icon>
        Đăng xuất
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view class="pa-6"></router-view>
    </v-main>
    <b-modal
      ref="my-modal"
      @ok="logout"
      cancel-title="Hủy"
      ok-title="Đồng ý"
      ok-
      title="Đăng xuất"
    >
      <p class="my-4">Bạn có đồng ý đăng xuất?</p>
    </b-modal>
    <!-- <v-dialog v-model="dialog" max-width="290" class="ma-12">
      <v-card>
        <v-card-title class="text-h5">
          Đăng xuất
        </v-card-title>

        <v-card-text>
          Bạn có đồng ý đăng xuất?
        </v-card-text>

        <v-card-actions class="text-center">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false">
            Đóng
          </v-btn>

          <v-btn color="primary" @click="dialog = false;logout()">
            Đồng ý
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions(["changeStatusDrawerAction"]),
    goToLogin() {
      this.$router.push({ name: "Login" });
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "Login" });
      });
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
  components: { Navbar },
  computed: {
    ...mapGetters(["loggedIn"]),
  },
};
</script>
