<template>
  <div class="content">
    <b-form @submit.prevent="onSearch">
      <b-input-group class="mb-2">
        <b-form-input
          type="search"
          v-model="keySearch"
          placeholder="Tìm kiếm"
        ></b-form-input>
        <b-button @click="onSearch" class="rounded-0" variant="outline-primary">
          <b-icon icon="search"></b-icon>
        </b-button>
      </b-input-group>
    </b-form>
    <!-- <v-container>
      <b-row class="my-1 text-end">
        <b-col cols="12">
          <b-button variant="primary" @click="showModalAdd">Thêm mới</b-button>
        </b-col>
      </b-row>
    </v-container> -->
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Danh sách câu hỏi</h4>
          </div>
          <div class="card-body">
            <table class="table table-striped walla">
              <thead>
                <tr align="center">
                  <th>STT</th>
                  <th>Ảnh</th>
                  <th>Tên</th>
                  <th>Nội dung</th>
                  <th>Thời gian</th>
                  <th>Tác vụ</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(chat, index) in chatList"
                  :key="chat.id"
                  align="center"
                >
                  <td>{{ index + 1 }}</td>
                   <td>
                    <b-avatar
                      button
                      @click="clickImage(`${base_url}` + chat.account[0].avatar)"
                      :src="`${base_url}` + chat.account[0].avatar"
                    >
                    </b-avatar>
                  </td>
                  <td>{{ chat.account[0].name }}</td>
                  <td>{{ chat.content }}</td>
                  <td>{{ new Date(chat.createdDate).toLocaleString() }}</td>
                 
                  <td>
                    <v-btn
                      @click="showModalDelete(chat)"
                      x-small
                      tile
                      color="error"
                      class="btn-sm py-3"
                    >
                      Xóa
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Số trang -->
    <b-pagination
      v-model="currentPage"
      :total-rows="total"
      :per-page="amount"
      aria-controls="my-table"
      class="my-6"
      align="end"
    ></b-pagination>
    <!-- Modal confirm delete -->
    <b-modal
      ref="modal-delete"
      @ok="onDelete()"
      cancel-title="Hủy"
      ok-title="Đồng ý"
      ok-
      title="Xóa"
    >
      <p class="my-4">Bạn có đồng ý xóa?</p>
    </b-modal>
  </div>
</template>

<script>
import ChatApi from "../apis/modules/chat";

export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API,
      chatList: [],
      amount: 10,
      page: 1,
      total: 0,
      keySearch: "",
      currentPage: 1,
      idDelete: null,
    };
  },
  watch: {
    currentPage: {
      handler: function (newPage) {
        this.page = newPage;
        this.getListFromSever();
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      ChatApi.getTotal({ keySearch: this.keySearch })
        .then((response) => {
          this.total = response.data.data;
          this.getListFromSever();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickImage(image) {
      window.open(image, "_blank");
    },
    getListFromSever() {
      ChatApi.getList({
        amount: this.amount,
        page: this.page - 1,
        keySearch: this.keySearch,
      })
        .then((response) => {
          this.chatList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSearch() {
      this.page = 1;
      this.init();
    },
    
    showModalDelete(chat) {
      this.idDelete = chat._id;
      this.$refs["modal-delete"].show();
    },
    hideModalDelete() {
      this.$refs["modal-delete"].hide();
    },
    onDelete() {
      ChatApi.delete(this.idDelete)
        .then(() => {
          const idDeleted = this.idDelete;
          this.chatList = this.chatList.filter(function (item) {
            return item._id !== idDeleted;
          });
          this.$toast.success("Xóa thành công");
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("Xóa thất bại");
        })
        .finally(() => {
          this.$refs["modal-delete"].hide();
          this.clearDataAdd();
        });
    },
  },
};
</script>
