quiz
<template>
  <div class="content">
    <b-form @submit.prevent="onSearch">
      <b-input-group class="mb-2">
        <b-form-input
          type="search"
          v-model="keySearch"
          placeholder="Tìm kiếm"
        ></b-form-input>
        <b-button @click="onSearch" class="rounded-0" variant="outline-primary">
          <b-icon icon="search"></b-icon>
        </b-button>
      </b-input-group>
    </b-form>
    <v-container>
      <b-row class="my-1 text-end">
        <b-col cols="12">
          <b-button variant="primary" @click="showModalAdd">Thêm mới</b-button>
        </b-col>
      </b-row>
    </v-container>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Danh sách bài thi</h4>
          </div>
          <div class="card-body">
            <table class="table table-striped walla">
              <thead>
                <tr align="center">
                  <th>STT</th>
                  <th>Tên tài</th>
                  <th>Trạng thái</th>
                  <th>Bài học</th>
                  <th>Thử thách</th>
                  <th>Số câu hỏi</th>
                  <th>Tác vụ</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(quiz, index) in quizList"
                  :key="quiz.id"
                  align="center"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ quiz.title }}</td>
                  <td>{{ quiz.isLock ? "Khóa" : "Mở khóa" }}</td>
                  <td>{{ quiz.post.length == 0 ? `` : quiz.post[0].name }}</td>
                  <td>
                    {{
                      quiz.category.length == 0 ? `` : quiz.category[0].title
                    }}
                  </td>
                  <td>{{ quiz.questionList.length }}</td>
                  
                  <td>
                    <v-btn
                      x-small
                      tile
                      @click="showModalOpen(quiz)"
                      color="success"
                      class="btn-sm py-3 mr-4"
                    >
                      Mở khóa
                    </v-btn>
                    <v-btn
                      x-small
                      tile
                      @click="showModalLock(quiz)"
                      color="primary"
                      class="btn-sm py-3 mr-4"
                    >
                      Khóa
                    </v-btn>
                    <v-btn
                      @click="showModalUpdatePost(quiz)"
                      x-small
                      tile
                      color="amber"
                      class="btn-sm py-3 mr-4"
                    >
                      Chọn bài học
                    </v-btn>
                    <v-btn
                      @click="showModalUpdateCategory(quiz)"
                      x-small
                      tile
                      color="teal"
                      class="btn-sm py-3 mr-4"
                    >
                      Chọn thử thách
                    </v-btn>

                    <v-btn
                      x-small
                      tile
                      color="primary"
                      class="btn-sm py-3 mr-4"
                      @click="viewDetail(quiz)"
                    >
                      Xem
                    </v-btn>

                    <v-btn
                      @click="showModalDelete(quiz)"
                      x-small
                      tile
                      color="error"
                      class="btn-sm py-3"
                    >
                      Xóa
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Số trang -->
    <b-pagination
      v-model="currentPage"
      :total-rows="total"
      :per-page="amount"
      aria-controls="my-table"
      class="my-6"
      align="end"
    ></b-pagination>
    <!-- Model View -->
    <b-modal
      size="xl"
      ref="modal-view"
      hide-footer
      :body-text-variant="primary"
      title="Xem thông tin"
    >
      <b-container fluid v-if="dataView != null">
        <b-row class="my-1">
          <!-- Tên bài thi -->
          <b-col cols="2">
            <label for="input-small">Tên bài thi:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              disabled
              :placeholder="`${dataView.title}`"
            ></b-form-input>
          </b-col>
          <!-- Miêu tả bài thi -->
          <b-col cols="2">
            <label for="input-small">Miêu tả bài thi:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              disabled
              :placeholder="`${dataView.description}`"
            ></b-form-input>
          </b-col>
          <!-- Miêu tả bài thi -->
          <b-col cols="2">
            <label for="input-small">Thời gian:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              disabled
              :placeholder="`${dataView.time} giây`"
            ></b-form-input>
          </b-col>
          <b-col
            cols="12"
            v-for="question in dataView.question"
            :key="question._id"
            align="left"
          >
            <tr>
              {{
                question.title
              }}
            </tr>
            <tr>
              {{
                question.threads
              }}
            </tr>
            <tr>
              {{
                question.question
              }}
            </tr>
            <tr v-for="answer in question.answer" :key="answer">
              {{
                answer.split("|")[0]
              }}
            </tr>
            <tr>
              --------------------------------------
            </tr>
            <tr>
              Đáp án:
              {{
                question.answer
                  .filter(function (item) {
                    return item.split("|")[1] == "true";
                  })[0]
                  .split("|")[0]
              }}
            </tr>
            <tr>
              Giải thích:
              {{
                question.explain
              }}
            </tr>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <!-- Model Add -->
    <b-modal
      size="lg"
      ref="modal-add"
      hide-footer
      :body-text-variant="primary"
      title="Thêm mới"
    >
      <b-container fluid>
        <b-row class="my-1">
          <b-col cols="12">
            <input
              type="file"
              id="file"
              ref="file"
              v-on:change="selectFileDone()"
            />
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12" class="text-center">
            <b-overlay
              :show="loadingAdd"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button variant="primary" @click="handleAdd">Thêm mới</b-button>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <!-- Modal confirm delete -->
    <b-modal
      ref="modal-delete"
      @ok="onDelete()"
      cancel-title="Hủy"
      ok-title="Đồng ý"
      ok-
      title="Xóa"
    >
      <p class="my-4">Bạn có đồng ý xóa?</p>
    </b-modal>
    <!-- Modal confirm open -->
    <b-modal
      ref="modal-open"
      @ok="onOpen()"
      cancel-title="Hủy"
      ok-title="Đồng ý"
      ok-
      title="Xóa"
    >
      <p class="my-4">Bạn có đồng ý mở khóa quiz?</p>
    </b-modal>

    <!-- Modal confirm lock -->
    <b-modal
      ref="modal-lock"
      @ok="onLock()"
      cancel-title="Hủy"
      ok-title="Đồng ý"
      ok-
      title="Xóa"
    >
      <p class="my-4">Bạn có đồng ý khóa quiz?</p>
    </b-modal>

    <!-- Model Chọn bài học -->
    <b-modal
      size="lg"
      ref="modal-edit-post"
      hide-footer
      :body-text-variant="primary"
      title="Chọn bài học"
    >
      <b-container fluid>
        <!-- Tìm kiếm bài học -->
        <b-row>
          <b-col cols="2">
            <label for="input-small">Bài học:</label>
          </b-col>
          <b-col cols="10">
            <b-input-group>
              <b-form-input
                id="input-small"
                size="sm"
                v-model="keySearchPost"
                placeholder="Tìm kiếm bài học"
              ></b-form-input>
              <b-button
                class="rounded-0"
                @click="onSearchPost"
                size="sm"
                variant="outline-primary"
              >
                <b-icon icon="search"></b-icon>
              </b-button>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <label for="input-small"></label>
          </b-col>
          <b-col cols="10">
            <v-container class="px-0 py-0 my-0" fluid>
              <b-form-group v-for="post in listPostSearch" :key="post._id">
                <b-form-radio
                  v-model="postSelected"
                  name="post"
                  :value="`${post._id}`"
                >
                  <b-avatar
                    :src="`${base_url}` + post.image"
                    size="2rem"
                  ></b-avatar>
                  {{ post.name }}</b-form-radio
                >
              </b-form-group>
            </v-container>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12" class="text-center">
            <b-overlay
              :show="loadingAdd"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button variant="primary" @click="handleUpdatePost"
                >Cập nhật</b-button
              >
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Model Chọn category -->
    <b-modal
      size="lg"
      ref="modal-edit-category"
      hide-footer
      :body-text-variant="primary"
      title="Chọn danh mục"
    >
      <b-container fluid>
        <!-- Tìm kiếm danh mục -->
        <b-row>
          <b-col cols="2">
            <label for="input-small">Danh mục:</label>
          </b-col>
          <b-col cols="10">
            <b-input-group>
              <b-form-input
                id="input-small"
                size="sm"
                v-model="keySearchCategory"
                placeholder="Tìm kiếm danh mục"
              ></b-form-input>
              <b-button
                class="rounded-0"
                @click="onSearchCategory"
                size="sm"
                variant="outline-primary"
              >
                <b-icon icon="search"></b-icon>
              </b-button>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <label for="input-small"></label>
          </b-col>
          <b-col cols="10">
            <v-container class="px-0 py-0 my-0" fluid>
              <b-form-group
                v-for="category in listCategorySearch"
                :key="category._id"
              >
                <b-form-radio
                  v-model="categorySelected"
                  name="category"
                  :value="`${category._id}`"
                >
                  <b-avatar
                    :src="`${base_url}` + category.image"
                    size="2rem"
                  ></b-avatar>
                  {{ category.title + ` - ` + category.type[0] }}</b-form-radio
                >
              </b-form-group>
            </v-container>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12" class="text-center">
            <b-overlay
              :show="loadingAdd"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button variant="primary" @click="handleUpdateCategory"
                >Cập nhật</b-button
              >
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import QuizApi from "../apis/modules/quiz";
import PostApi from "../apis/modules/post";
import CategoryApi from "../apis/modules/category";

export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API,
      quizList: [],
      listPostSearch: [],
      listCategorySearch: [],
      postSelected: null,
      categorySelected: null,
      amount: 10,
      page: 1,
      total: 0,
      idStatus: null,
      keySearch: "",
      keySearchPost: "",
      currentPage: 1,
      file: null,
      title: null,
      description: null,
      loadingAdd: false,
      dataView: null,
      idDelete: null,
      quizSelected: null,
      type: "Challenge",
    };
  },
  watch: {
    currentPage: {
      handler: function (newPage) {
        this.page = newPage;
        this.getListFromSever();
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      QuizApi.getTotal({ keySearch: this.keySearch })
        .then((response) => {
          this.total = response.data.data;
          this.getListFromSever();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickImage(image) {
      window.open(image, "_blank");
    },
    getListFromSever() {
      QuizApi.getList({
        amount: this.amount,
        page: this.page - 1,
        keySearch: this.keySearch,
      })
        .then((response) => {
          this.quizList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSearch() {
      this.page = 1;
      this.init();
    },
    showModalAdd() {
      this.clearDataAdd();
      this.$refs["modal-add"].show();
    },
    showModalUpdatePost(quiz) {
      this.clearDataAdd();
      this.quizSelected = quiz;
      this.$refs["modal-edit-post"].show();
    },
    showModalUpdateCategory(quiz) {
      this.clearDataAdd();
      this.quizSelected = quiz;
      this.$refs["modal-edit-category"].show();
    },
    clearDataAdd() {
      this.file = null;
      this.title = null;
      this.keySearchPost = "";
      this.keySearchCategory = "";
      this.description = null;
      this.idDelete = null;
      this.quizSelected = null;
      this.type = "Challenge";
      this.postSelected = null;
      this.categorySelected = null;
      this.listPostSearch = [];
      this.listCategorySearch = [];
    },
    selectFileDone() {
      this.file = this.$refs.file.files[0];
    },
    handleAdd() {
      this.loadingAdd = true;
      if (this.file == null) {
        this.$toast.error("Vui lòng chọn file csv");
        this.loadingAdd = false;
        return;
      }

      let formData = new FormData();
      formData.append("filecsv", this.file);
      QuizApi.add(formData)
        .then((response) => {
          this.quizList.push(response.data.data);
          this.$toast.success("Thêm thành công");
        })
        .catch(() => {
          this.$toast.error("Thêm thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-add"].hide();
          this.clearDataAdd();
        });
    },
    viewDetail(item) {
      this.dataView = item;
      this.$refs["modal-view"].show();
    },
    showModalDelete(artist) {
      this.idDelete = artist._id;
      this.$refs["modal-delete"].show();
    },
    hideModalDelete() {
      this.$refs["modal-delete"].hide();
    },
    onDelete() {
      QuizApi.delete(this.idDelete)
        .then(() => {
          const idDeleted = this.idDelete;
          this.quizList = this.quizList.filter(function (item) {
            return item._id !== idDeleted;
          });
          this.$toast.success("Xóa thành công");
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("Xóa thất bại");
        })
        .finally(() => {
          this.$refs["modal-delete"].hide();
          this.clearDataAdd();
        });
    },
    onSearchPost() {
      PostApi.getList({ keySearch: this.keySearchPost, amount: 5, page: 0 })
        .then((response) => {
          this.listPostSearch = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSearchCategory() {
      CategoryApi.getList({
        keySearch: this.keySearchCategory,
        amount: 5,
        page: 0,
      })
        .then((response) => {
          this.listCategorySearch = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleUpdatePost() {
      this.loadingAdd = true;
      if (this.postSelected == null) {
        this.$toast.error("Vui lòng chọn bài học");
        this.loadingAdd = false;
        return;
      }

      QuizApi.editPost({
        id: this.quizSelected._id,
        idPost: this.postSelected,
      })
        .then((response) => {
          const objIndex = this.quizList.findIndex(
            (obj) => obj._id == response.data.data._id
          );
          this.quizList[objIndex] = response.data.data;
          this.$toast.success("Cập nhật thành công");
        })
        .catch(() => {
          this.$toast.error("Cập nhật thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-edit-post"].hide();
          this.clearDataAdd();
        });
    },
    handleUpdateCategory() {
      this.loadingAdd = true;
      if (this.categorySelected == null) {
        this.$toast.error("Vui lòng chọn danh mục");
        this.loadingAdd = false;
        return;
      }

      if (this.quizSelected.time == 0) {
        this.$toast.error("Vui lòng chọn bài thi có thời gian khác bằng 0");
        this.loadingAdd = false;
        return;
      }

      QuizApi.editCategory({
        id: this.quizSelected._id,
        idCategory: this.categorySelected,
      })
        .then((response) => {
          const objIndex = this.quizList.findIndex(
            (obj) => obj._id == response.data.data._id
          );
          this.quizList[objIndex] = response.data.data;
          this.$toast.success("Cập nhật thành công");
        })
        .catch(() => {
          this.$toast.error("Cập nhật thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-edit-category"].hide();
          this.clearDataAdd();
        });
    },
    showModalOpen(account) {
      this.idStatus = account._id;
      this.$refs["modal-open"].show();
    },
    onOpen() {
      this.loadingAdd = true;

      QuizApi.changeLock({
        idQuiz: this.idStatus,
        status: false,
      })
        .then((response) => {
          const objIndex = this.quizList.findIndex(
            (obj) => obj._id == response.data.data._id
          );
          this.quizList[objIndex] = response.data.data;
          this.$toast.success("Mở khóa thành công");
        })
        .catch(() => {
          this.$toast.error("Mở khóa thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-open"].hide();
          this.idStatus = null;
        });
    },
     showModalLock(account) {
      this.idStatus = account._id;
      this.$refs["modal-lock"].show();
    },
     onLock() {
      this.loadingAdd = true;

      QuizApi.changeLock({
        idQuiz: this.idStatus,
        status: true,
      })
        .then((response) => {
          const objIndex = this.quizList.findIndex(
            (obj) => obj._id == response.data.data._id
          );
          this.quizList[objIndex] = response.data.data;
          this.$toast.success("Khóa thành công");
        })
        .catch(() => {
          this.$toast.error("Khóa thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-lock"].hide();
          this.idStatus = null;
        });
    },
  },
};
</script>
