import Vue from "vue"
import Vuex from "vuex"
import Navbar from "./modules/Navbar"

import * as actions from "./actions"
import * as mutations from "./mutations"
import * as getters from "./getters"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:localStorage.getItem("access_token") || null,
    navs: [
      {name: "Danh mục trang chủ", router: "/homeCategory", icon: "mdi-home-modern"},
      {name: "Danh mục thử thách", router: "/category", icon: "mdi-format-list-bulleted-type"},
      {name: "Bài thi", router: "/quiz", icon: "mdi-school"},
      {name: "Bài học", router: "/post", icon: "mdi-book-open-page-variant"},
      {name: "File tài liệu", router: "/pdf", icon: "mdi-file"},
      {name: "Thông báo", router: "/notification", icon: "mdi-bell-ring-outline"},
      {name: "Tài khoản", router: "/account", icon: "mdi-account"},
      {name: "Câu hỏi", router: "/question", icon: "mdi-comment-question-outline"},
     
    ]
  },
  getters,
  mutations,
  actions,
  modules: {
    Navbar,
  
  },
})
