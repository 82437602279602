import Api from "../index";

export default {
  getList(credentials) {
    return Api().post("web/account/getList", {
      amount: credentials.amount,
      page: credentials.page,
      keySearch: credentials.keySearch,
    });
  },
 
  getTotal(credentials) {
    return Api().post("web/account/getTotal", {
      keySearch: credentials.keySearch,
    });
  },
  delete(credentials) {
    return Api().post("web/account/delete", {
      idUser: credentials,
    });
  },
  changeLock(credentials) {
    return Api().post("web/account/changeStatusLockAccount", credentials);
  },
  add(credentials) {
    return Api().post("web/account/addAccount", {
      email: credentials.email,
      name: credentials.name,
      password: credentials.password,
    });
  },
  
};
