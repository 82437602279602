import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import HomeCategory from '../views/HomeCategory.vue'
import Category from '../views/Category.vue'
import Quiz from '../views/Quiz.vue'
import Post from '../views/Post.vue'
import Pdf from '../views/Pdf.vue'
import Notification from '../views/Notification.vue'
import Account from '../views/Account.vue'
import Chat from '../views/Chat.vue'
import Policy from '../views/Policy.vue'

import store from '../store'


Vue.use(VueRouter)

const routes = [
  
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresVisitor: true,
            title: "Đăng nhập",
        }
    },
    {
        path: '/policy',
        name: 'Policy',
        component: Policy,
        meta: {
            requiresVisitor: true,
            title: "Policy",
        }
    },
    {
        path: '/homeCategory',
        name: 'HomeCategory',
        component: HomeCategory,
        meta: {
            requiresAuth: true,
            title: "Danh mục trang chủ",
        }
    },
    {
        path: '/category',
        name: 'category',
        component: Category,
        meta: {
            requiresAuth: true,
            title: "Danh mục thử thách",
        }
    },
    {
        path: '/quiz',
        name: 'quiz',
        component: Quiz,
        meta: {
            requiresAuth: true,
            title: "Bài thi",
        }
    },
    {
        path: '/post',
        name: 'post',
        component: Post,
        meta: {
            requiresAuth: true,
            title: "Bài học",
        }
    },
    {
        path: '/pdf',
        name: 'pdf',
        component: Pdf,
        meta: {
            requiresAuth: true,
            title: "PDF",
        }
    },
    {
        path: '/notification',
        name: 'Notification',
        component: Notification,
        meta: {
            requiresAuth: true,
            title: "Thông báo",
        }
    },
    {
        path: '/account',
        name: 'Account',
        component: Account,
        meta: {
            requiresAuth: true,
            title: "Tài khoản",
        }
    },
    {
        path: '/question',
        name: 'Chat',
        component: Chat,
        meta: {
            requiresAuth: true,
            title: "Câu hỏi",
        }
    },
]

const router = new VueRouter({
    mode: "history",
    routes
})
router.beforeEach(((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.loggedIn) {
            next({name: "Login"})
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresVisitor)) {
        if (store.getters.loggedIn) {
            next({name: "HomeCategory"})
        } else {
            next()
        }
    } else {
        next({name: "Login"})
    }
}))

export default router
