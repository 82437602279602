<template>
  <div class="content">
    <b-form @submit.prevent="onSearch">
      <b-input-group class="mb-2">
        <b-form-input
          type="search"
          v-model="keySearch"
          placeholder="Tìm kiếm"
        ></b-form-input>
        <b-button @click="onSearch" class="rounded-0" variant="outline-primary">
          <b-icon icon="search"></b-icon>
        </b-button>
      </b-input-group>
    </b-form>
    <v-container>
      <b-row class="my-1 text-end">
        <b-col cols="12">
          <b-button variant="primary" @click="showModalAdd">Thêm mới</b-button>
        </b-col>
      </b-row>
    </v-container>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Danh mục thử thách</h4>
          </div>
          <div class="card-body">
            <table class="table table-striped walla">
              <thead>
                <tr align="center">
                  <th>STT</th>
                  <th>Tiêu đề</th>
                  <th>Loại</th>
                  <th>Ảnh</th>
                  <th>Tác vụ</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(category, index) in categoryList"
                  :key="category.id"
                  align="center"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ category.title }}</td>
                  <td>{{ category.type[0] }}</td>
                  <td>
                    <b-avatar
                      button
                      @click="clickImage(`${base_url}` + category.image)"
                      :src="`${base_url}` + category.image"
                    >
                    </b-avatar>
                  </td>
                  <td>
                    <v-btn
                      x-small
                      tile
                      color="primary"
                      class="btn-sm py-3 mr-4"
                      @click="viewDetail(category)"
                    >
                      Xem
                    </v-btn>
                    <v-btn
                      x-small
                      tile
                      @click="showModalEdit(category)"
                      color="success"
                      class="btn-sm py-3 mr-4"
                    >
                      Sửa
                    </v-btn>
                    <v-btn
                      @click="showModalDelete(category)"
                      x-small
                      tile
                      color="error"
                      class="btn-sm py-3"
                    >
                      Xóa
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Số trang -->
    <b-pagination
      v-model="currentPage"
      :total-rows="total"
      :per-page="amount"
      aria-controls="my-table"
      class="my-6"
      align="end"
    ></b-pagination>
    <!-- Model View -->
    <b-modal
      size="lg"
      ref="modal-view"
      hide-footer
      :body-text-variant="primary"
      title="Xem thông tin"
    >
      <b-container fluid v-if="dataView != null">
        <b-row class="mb-1 text-center">
          <b-col cols="12">
            <b-avatar
              button
              @click="clickImage(`${base_url}` + dataView.image)"
              :src="`${base_url}` + dataView.image"
              size="12rem"
            ></b-avatar>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Tiêu đề:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              disabled
              :placeholder="`${dataView.title}`"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Loại:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              disabled
              :placeholder="`${
                dataView.type
              }`"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <!-- Model Add -->
    <b-modal
      size="lg"
      ref="modal-add"
      hide-footer
      :body-text-variant="primary"
      title="Thêm mới"
    >
      <b-container fluid>
        <b-row class="mb-1 text-center">
          <b-col cols="12">
            <b-avatar
              button
              @click="$refs.file.click()"
              :src="fileSrc"
              size="12rem"
            ></b-avatar>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12">
            <input
              type="file"
              id="file"
              ref="file"
              style="display: none"
              v-on:change="selectFileDone()"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <label for="input-small">Tiêu đề:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="title"
              placeholder="Nhập tiêu đề"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <label for="input-small">Loại:</label>
          </b-col>
          <b-col cols="10">
            <b-dropdown :text="type" variant="outline-primary">
              <b-dropdown-item @click="type = 'Challenge'"
                >Challenge</b-dropdown-item
              >
              <b-dropdown-item @click="type = 'English'"
                >English</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12" class="text-center">
            <b-overlay
              :show="loadingAdd"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button variant="primary" @click="handleAdd">Thêm mới</b-button>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Model Edit -->
    <b-modal
      size="lg"
      ref="modal-edit"
      hide-footer
      :body-text-variant="primary"
      title="Sửa thông tin"
    >
      <b-container fluid>
        <b-row class="mb-1 text-center">
          <b-col cols="12">
            <b-avatar
              button
              @click="$refs.file.click()"
              :src="fileSrc"
              size="12rem"
            ></b-avatar>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12">
            <input
              type="file"
              id="file"
              ref="file"
              style="display: none"
              v-on:change="selectFileDone()"
            />
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Tiêu đề:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="title"
            ></b-form-input>
          </b-col>
        </b-row>

    

        <b-row class="my-1">
          <b-col cols="12" class="text-center">
            <b-overlay
              :show="loadingAdd"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button variant="primary" @click="handleEdit"
                >Cập nhật</b-button
              >
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <!-- Modal confirm delete -->
    <b-modal
      ref="modal-delete"
      @ok="onDelete()"
      cancel-title="Hủy"
      ok-title="Đồng ý"
      ok-
      title="Xóa"
    >
      <p class="my-4">Bạn có đồng ý xóa?</p>
    </b-modal>
  </div>
</template>

<script>
import CategoryApi from "../apis/modules/category";

export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API,
      categoryList: [],
      amount: 10,
      page: 1,
      total: 0,
      keySearch: "",
      currentPage: 1,
      file: null,
      title: null,
      description: null,
      loadingAdd: false,
      fileSrc: null,
      dataView: null,
      idEdit: null,
      idDelete: null,
      type: "Challenge",
    };
  },
  watch: {
    currentPage: {
      handler: function (newPage) {
        this.page = newPage;
        this.getListFromSever();
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      CategoryApi.getTotal({ keySearch: this.keySearch })
        .then((response) => {
          this.total = response.data.data;
          this.getListFromSever();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickImage(image) {
      window.open(image, "_blank");
    },
    getListFromSever() {
      CategoryApi.getList({
        amount: this.amount,
        page: this.page - 1,
        keySearch: this.keySearch,
      })
        .then((response) => {
          this.categoryList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSearch() {
      this.page = 1;
      this.init();
    },
    showModalAdd() {
      this.clearDataAdd();
      this.$refs["modal-add"].show();
    },
    clearDataAdd() {
      this.file = null;
      this.title = null;
      this.description = null;
      this.fileSrc = null;
      this.idEdit = null;
      this.idDelete = null;
      this.type = "Challenge";
    },
    selectFileDone() {
      this.file = this.$refs.file.files[0];

      this.fileSrc = URL.createObjectURL(this.file);
    },
    handleAdd() {
      this.loadingAdd = true;
      if (this.file == null) {
        this.$toast.error("Vui lòng chọn ảnh");
        this.loadingAdd = false;
        return;
      }
      if (this.title == null) {
        this.$toast.error("Vui lòng nhập tiêu đề");
        this.loadingAdd = false;
        return;
      }

      let formData = new FormData();
      formData.append("image", this.file);
      formData.append("title", this.title);
      formData.append("type", this.type);
      CategoryApi.add(formData)
        .then((response) => {
          this.categoryList.push(response.data.data);
          this.$toast.success("Thêm thành công");
        })
        .catch(() => {
          this.$toast.error("Thêm thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-add"].hide();
          this.clearDataAdd();
        });
    },
    viewDetail(item) {
      this.dataView = item;
      this.$refs["modal-view"].show();
    },
    showModalEdit(item) {
      this.clearDataAdd();
      this.fileSrc = this.base_url + item.image;
      this.title = item.title;
      this.idEdit = item._id;
      this.description = item.description;
      this.$refs["modal-edit"].show();
    },
    handleEdit() {
      this.loadingAdd = true;
      if (this.title == null) {
        this.$toast.error("Vui lòng nhập tiêu đề");
        this.loadingAdd = false;
        return;
      }
     
      let formData = new FormData();
      if (this.file != null) {
        formData.append(
          "linkImageRemove",
          this.fileSrc.replace(this.base_url, "")
        );
        formData.append("image", this.file);
      }
      formData.append("title", this.title);
      formData.append("id", this.idEdit);
      CategoryApi.edit(formData)
        .then((response) => {
          const objIndex = this.categoryList.findIndex(
            (obj) => obj._id == response.data.data._id
          );
          this.categoryList[objIndex] = response.data.data;
          this.$toast.success("Cập nhật thành công");
        })
        .catch(() => {
          this.$toast.error("Cập nhật thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-edit"].hide();
          this.clearDataAdd();
        });
    },
    showModalDelete(artist) {
      this.idDelete = artist._id;
      this.$refs["modal-delete"].show();
    },
    hideModalDelete() {
      this.$refs["modal-delete"].hide();
    },
    onDelete() {
      CategoryApi.delete(this.idDelete)
        .then(() => {
          console.log("ABC");
          const idDeleted = this.idDelete;
          this.categoryList = this.categoryList.filter(function (item) {
            return item._id !== idDeleted;
          });
          this.$toast.success("Xóa thành công");
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("Xóa thất bại");
        })
        .finally(() => {
          this.$refs["modal-delete"].hide();
          this.clearDataAdd();
        });
    },
  },
};
</script>
