<template>
  <v-container class="spacing-playground pa-16 h-100">
    <v-card width="50%" class="pa-16 mx-auto">
      <v-img
        contain
        max-height="150"
        max-width="150"
        class="my-12 mx-auto"
        src="../assets/logo.png"
      ></v-img>
      <v-form @submit.prevent="login">
        <v-text-field
          dense
          label="Tên tài khoản"
          v-model="username"
          class="my-6"
          required
        ></v-text-field>

        <v-text-field
          type="password"
          dense
          class="my-6"
          label="Password"
          v-model="password"
          required
        ></v-text-field>
        <div class="text-center">
          <v-btn :loading="loading"       color="secondary"
 type="submit">
            Đăng nhập
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      loading: false,
    }
  },

  methods: {
    login() {
      this.loading = true
      this.$store
        .dispatch("login", { username: this.username, password: this.password })
        .then(() => {
          this.$router.push({ name: "HomeCategory" })
        })
        .catch((error) => {
          console.log("Loi" + error)
          this.$toast.error("Tài khoản hoặc mật khẩu không đúng")
        })
        .finally(() => {
          console.log("Xong")
          this.loading = false
        })
    },
  },
}
</script>
<style>
.custom-loader {
  display: flex;
  animation: loader 1s infinite
}

@-moz-keyframes loader {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
}

@keyframes loader {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
}
</style>
