<template>
  <div class="content">
    <b-form @submit.prevent="onSearch">
      <b-input-group class="mb-2">
        <b-form-input
          type="search"
          v-model="keySearch"
          placeholder="Tìm kiếm"
        ></b-form-input>
        <b-button @click="onSearch" class="rounded-0" variant="outline-primary">
          <b-icon icon="search"></b-icon>
        </b-button>
      </b-input-group>
    </b-form>

    <v-container>
      <b-row class="my-1 text-end">
        <b-col cols="12">
          <b-button variant="primary" @click="showModalAdd">Thêm mới</b-button>
        </b-col>
      </b-row>
    </v-container>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Thông báo</h4>
          </div>
          <div class="card-body">
            <table class="table table-striped walla">
              <thead>
                <tr align="center">
                  <th>STT</th>
                  <th>Tiêu đề</th>
                  <th>Nội dung</th>
                  <th>Tác vụ</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(notification, index) in listCategory"
                  :key="notification.id"
                  align="center"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ notification.title }}</td>
                  <td style="max-width: 500px;">{{ notification.contentNotification }}</td>
                  <td>
                    <v-btn
                      x-small
                      tile
                      color="primary"
                      class="btn-sm py-3 mr-4"
                      @click="viewDetail(notification)"
                    >
                      Xem
                    </v-btn>
                  
                    <v-btn
                      @click="showModalDelete(notification)"
                      x-small
                      tile
                      color="error"
                      class="btn-sm py-3"
                    >
                      Xóa
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Số trang -->
    <b-pagination
      v-model="currentPage"
      :total-rows="total"
      :per-page="amount"
      aria-controls="my-table"
      class="my-6"
      align="end"
    ></b-pagination>

    <!-- Model View -->
    <b-modal
      size="lg"
      ref="modal-view"
      hide-footer
      :body-text-variant="primary"
      title="Xem thông tin"
    >
      <b-container fluid v-if="dataView != null">
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Tiêu đề:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              disabled
              :placeholder="`${dataView.title}`"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Nội dung bên ngoài:</label>
          </b-col>
          <b-col cols="10">
            <b-form-textarea
              id="input-small"
              size="sm"
              rows="3"
              max-rows="10"
              disabled
              :placeholder="`${dataView.contentNotification}`"
            ></b-form-textarea>
          </b-col>
        </b-row>
         <b-row class="my-1">
          <b-col cols="2">
            <label for="input-small">Nội dung bên trong:</label>
          </b-col>
          <b-col cols="10">
          <b-form-textarea
            id="input-small"
            size="sm"
            disabled
            rows="3"
            max-rows="10"
            :placeholder="`${dataView.content}`"
          ></b-form-textarea>
        </b-col>
        </b-row>
        
      </b-container>
    </b-modal>
    <!-- Model Add -->
    <b-modal
      size="lg"
      ref="modal-add"
      scrollable
      hide-footer
      :body-text-variant="primary"
      title="Thêm mới"
    >
      <b-container fluid>
      
        <b-row>
          <b-col cols="2">
            <label for="input-small">Tiêu đề:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="input-small"
              size="sm"
              v-model="title"
              placeholder="Tiêu đề"
            ></b-form-input>
          </b-col>
        </b-row>
         <b-row>
          <b-col cols="2">
            <label for="input-small">Nội dung thông báo bên ngoài:</label>
          </b-col>
          <b-col cols="10">
            <b-form-textarea
              id="input-small"
              size="sm"
              rows="3"
              max-rows="20"
              v-model="contentNotification"
              placeholder="Nội dung thông báo bên  ngoài"
            ></b-form-textarea>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <label for="input-small">Nội dung thông báo bên trong:</label>
          </b-col>
          <b-col cols="10">
            <b-form-textarea
              id="input-small"
              size="sm"
              v-model="content"
              rows="3"
              max-rows="20"
              placeholder="Nội dung thông báo bên trong"
            ></b-form-textarea>
          </b-col>
        </b-row>
      
    
        <b-row class="my-1">
          <b-col cols="12" class="text-center">
            <b-overlay
              :show="loadingAdd"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button variant="primary" @click="handleAdd">Thêm mới</b-button>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Modal confirm delete -->
    <b-modal
      ref="modal-delete"
      @ok="onDelete()"
      cancel-title="Hủy"
      ok-title="Đồng ý"
      ok-
      title="Xóa"
    >
      <p class="my-4">Bạn có đồng ý xóa?</p>
    </b-modal>

  </div>
</template>

<script>
import NotificationApi from "../apis/modules/notification";

export default {
  data() {
    return {
      base_url: process.env.VUE_APP_API,
      listCategory: [],
      amount: 10,
      page: 1,
      total: 0,
      keySearch: "",
      currentPage: 1,
      title: null,
      contentNotification: null,
      content: null,
      loadingAdd: false,
      dataView: null,
      idDelete: null,
    };
  },
  watch: {
    currentPage: {
      handler: function (newPage) {
        this.page = newPage;
        this.getListFromSever();
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      NotificationApi.getTotal({ keySearch: this.keySearch })
        .then((response) => {
          this.total = response.data.data;
          this.getListFromSever();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickImage(image) {
      window.open(image, "_blank");
    },
    getListFromSever() {
      NotificationApi.getList({
        amount: this.amount,
        page: this.page - 1,
        keySearch: this.keySearch,
      })
        .then((response) => {
          this.listCategory = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSearch() {
      this.page = 1;
      this.init();
    },
    showModalAdd() {
      this.clearDataAdd();
      this.$refs["modal-add"].show();
    },
    clearDataAdd() {
      this.title = null;
      this.content = null;
      this.contentNotification = null;
      this.idDelete = null;
    },
    
    handleAdd() {
      this.loadingAdd = true;
      
      if (this.title == null) {
        this.$toast.error("Vui lòng nhập tiêu đề");
        this.loadingAdd = false;
        return;
      }
      if (this.content == null) {
        this.$toast.error("Vui lòng nhập nội dung bên trong");
        this.loadingAdd = false;
        return;
      }
      if (this.contentNotification == null) {
        this.$toast.error("Vui lòng nhập nội dung bên ngoài");
        this.loadingAdd = false;
        return;
      }
      NotificationApi.add({
          content:this.content,
          title:this.title,
          contentNotification: this.contentNotification
      })
        .then((response) => {
          this.listCategory.push(response.data.data);
          this.$toast.success("Thêm thành công");
        })
        .catch(() => {
          this.$toast.error("Thêm thất bại");
        })
        .finally(() => {
          this.loadingAdd = false;
          this.$refs["modal-add"].hide();
          this.clearDataAdd();
        });
    },
    viewDetail(item) {
      this.dataView = item;
      this.$refs["modal-view"].show();
    },
   
    showModalDelete(artist) {
      this.idDelete = artist._id;
      this.$refs["modal-delete"].show();
    },
    hideModalDelete() {
      this.$refs["modal-delete"].hide();
    },
    onDelete() {
      NotificationApi.delete(this.idDelete)
        .then(() => {
          console.log("ABC");
          const idDeleted = this.idDelete;
          this.listCategory = this.listCategory.filter(function (item) {
            return item._id !== idDeleted;
          });
          this.$toast.success("Xóa thành công");
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("Xóa thất bại");
        })
        .finally(() => {
          this.$refs["modal-delete"].hide();
          this.clearDataAdd();
        });
    },
  },
};
</script>
