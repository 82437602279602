import Api from "../index";

export default {
  getList(credentials) {
    return Api().post("web/pdf/getList", {
      amount: credentials.amount,
      page: credentials.page,
      keySearch: credentials.keySearch,
    });
  },
 
  getTotal(credentials) {
    return Api().post("web/pdf/getTotal", {
      keySearch: credentials.keySearch,
    });
  },
  add(credentials) {
    return Api().post("web/pdf/add", credentials, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  edit(credentials) {
    return Api().post("web/pdf/edit", credentials,);
  },
};
