import Api from "../index";

export default {
  getList(credentials) {
    return Api().post("web/quiz/getList", {
      amount: credentials.amount,
      page: credentials.page,
      keySearch: credentials.keySearch,
    });
  },
 
  getTotal(credentials) {
    return Api().post("web/quiz/getTotal", {
      keySearch: credentials.keySearch,
    });
  },
  add(credentials) {
    return Api().post("web/quiz/add", credentials, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  delete(credentials) {
    return Api().post("web/quiz/delete", {
      id: credentials,
    });
  },
  editPost(credentials) {
    return Api().post("web/quiz/editPost", credentials, {
    });
  },
  editCategory(credentials) {
    return Api().post("web/quiz/editCategory", credentials, {
    });
  },
  changeLock(credentials) {
    return Api().post("web/quiz/changeStatusLockQuiz", credentials);
  },

};
